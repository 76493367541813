import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApEdcRcptService {



    
    list_edc_rcpt_template(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_edc_rcpt/list_edc_rcpt_template", form);

    }

    view_edc_rcpt_template(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_edc_rcpt/view_edc_rcpt_template", form);
    }
    
    update_edc_rcpt_template(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_edc_rcpt/update_edc_rcpt_template", form);
    }
    insert_edc_rcpt_template(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_edc_rcpt/insert_edc_rcpt_template", form);

    }
    preview_edc_rcpt_template(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_edc_rcpt/preview_edc_rcpt_template", form);

    }    
    ///////////////////////////////////////////////////////////////////////////
        

}

export default new ApEdcRcptService();