<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>{{ form.rt_name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-toolbar dark color="panel1"><v-toolbar-title>Info</v-toolbar-title>
                    <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             
                <v-card class="mt-5">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.rt_code" disabled label="Code" outlined></v-text-field>                                
                                <v-text-field v-model="form.rt_name" label="Name" :rules="inputRules" outlined></v-text-field>
                                <v-select
                                    v-model="form.receipt_type_code"
                                    :items="rtTypeList"
                                    item-text="description"
                                    item-value="code"
                                    label="Type"
                                    standard
                                    :rules="inputRules"
                                     outlined
                                ></v-select>
                                <v-select
                                    v-model="form.status"
                                    :items="statusList"
                                    item-text="description"
                                    item-value="Code"
                                    label="Status"
                                    standard
                                    :rules="inputRules"
                                     outlined
                                ></v-select>
                                <v-textarea label="Receipt Format" v-model="form.rt_format" rows="20"></v-textarea>
                                <!-- <editor :content.sync="form.rt_format"></editor> -->
                                <v-btn @click="preview">Preview</v-btn>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>

                </v-card>

            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-toolbar dark color="panelSysInfo"><v-toolbar-title>System Info</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-5">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="info in systemInfo" :key="info.alias" :value="form[info.name]" disabled :label="info.alias" outlined></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>

                </v-card>

                
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApEdcRcptService from "@/services/ApEdcRcptService"
import ApParameterService from "@/services/ApParameterService"
// import Editor from "@/components/Editor.vue";
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Merchants',disabled: false,href:'/merchants/',},
              {text:'Receipt Template',disabled: false,href:'/merchants/',},
              {text:'Details',disabled: false,href:'#',},
              ],

            
            statusList:[],
            rtTypeList:[],
            routeParam:[],            
            
            form:{login_id:null,receipt_type_code:""},
            logo:'',
            inputRules: [
             v => !!v || 'This field is required'
            ],

            cpFiles:{
                file_type:'image',
                route_type:'merchants',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},
            systemInfo:[
                {"name":"created_date","alias":"Created Date"},
                {"name":"created_by","alias":"Created By"},
                {"name":"created_at","alias":"Created At"},
                {"name":"updated_date","alias":"Updated Date"},
                {"name":"updated_by","alias":"Updated By"},
                {"name":"updated_at","alias":"Updated At"},
            ],

    }
  },
  async created() {
    this.routeParam['edc_rcpt_template_id'] = this.$route.params.edc_rcpt_template_id;
    this.getParameter();
    this.viewDetail();

  },
  
  methods:{

    async viewDetail(){
        this.form.edc_rcpt_template_id = this.routeParam.edc_rcpt_template_id
        try{
            ApEdcRcptService.view_edc_rcpt_template(this.form).then((res) => {
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                console.log(res.data);
                
            }).catch((e)=>{
                console.log(e.response);
                //this.$refs.dlgMsg.showDlgBox(true,'error','View EDC',e.response.data.error_message);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){
        try{
            ApEdcRcptService.update_edc_rcpt_template(this.form).then(() => {
                this.$refs.dlgMsg.showDlgBox(true,'success','Update EDC Receipt Template','Success.');
            }).catch((e)=>{
                console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Update EDC Receipt Template',e.response.data.error_message);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    
    dlgClose(){
        this.visible = false;        
    },
        async getParameter(){
            try{
                //let parameter = {login_id:"",param_type:"add-edc",merchant_id:this.routeParam['merchant_id']}
                let parameter = {login_id:"",param_type:"add-edc_rcpt_template"}
                ApParameterService.get_parameter(parameter).then((res) => {
                    this.statusList = res.data.statusList;
                    this.rtTypeList = res.data.rtTypeList;
                    console.log(res.data);
                    
                }).catch((e)=>{
                    console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Get Parameter',e.response.data.error_message);
                })
                ;
            } catch(e) {
                console.log(e);
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }
        },

    async preview(){
        this.form.edc_rcpt_template_id = this.routeParam.edc_rcpt_template_id
        try{
            ApEdcRcptService.preview_edc_rcpt_template(this.form).then((res) => {
                let url = res.data.fileUrl;
                window.open(url,'_blank')
                console.log(res.data);
                
            }).catch((e)=>{
                console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','View EDC',e.response);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },        

  }
}
</script>